import React, { useState } from 'react';
import classNames from 'classnames';
import * as XLSX from 'xlsx'; // Import xlsx library

const Table = ({ columns, data, fetchTransactions }) => {
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const [exportOption, setExportOption] = useState('current'); // State to handle export option (current page or entire dataset)

  // Sorting function
  const sortedData = [...data].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    }
    return 0;
  });

  // Handle sorting
  const handleSort = (key) => {
    setSortConfig(prevConfig => {
      let direction = 'asc';
      if (prevConfig.key === key && prevConfig.direction === 'asc') {
        direction = 'desc';
      }
      return { key, direction };
    });
  };

  // Convert the data to Excel format using xlsx
  const convertToExcel = (data) => {
    // Convert table data to array of arrays
    const headers = columns.map(col => col.Header);
    const rows = data.map(item => {
      return columns.map(col => {
        // Format the data based on column accessor (special case for time)
        if (col.accessor === 'time') {
          return new Date(item.time).toLocaleString();
        }
        return item[col.accessor] || '';
      });
    });

    // Create a worksheet and workbook
    const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Write to Excel file
    XLSX.writeFile(wb, 'table_data.xlsx');
  };

  // Trigger Excel download
  const handleExport = () => {
    const dataToExport = exportOption === 'current' ? sortedData : data; // Use either current page or entire dataset
    convertToExcel(dataToExport);
  };

  return (
    <div className="overflow-x-auto">
      {/* Export Button */}
      <div className="mb-4 flex justify-between items-center">
        <button
          onClick={handleExport}
          className="bg-blue-500 text-white px-4 py-2 rounded-md"
        >
          Export Data
        </button>

        {/* Export Option */}
        <select
          value={exportOption}
          onChange={(e) => setExportOption(e.target.value)}
          className="mt-1 block w-1/4 py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          <option value="current">Current Page</option>
          <option value="all">Entire Dataset</option>
        </select>
      </div>

      {/* Scrollable Table Container */}
      <div className="overflow-x-auto max-h-[600px]">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {columns.map((column, index) => (
                <th
                  key={index}
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer transition-colors duration-200 hover:bg-gray-200"
                  onClick={() => handleSort(column.accessor)}
                >
                  {column.Header}
                  {sortConfig.key === column.accessor && (
                    <span className={classNames('ml-2', {
                      'text-gray-400': sortConfig.direction === 'asc',
                      'text-gray-900': sortConfig.direction === 'desc',
                    })}>
                      {sortConfig.direction === 'asc' ? '▲' : '▼'}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {sortedData.map((item) => (
              <tr
                key={item.id}
                className="hover:bg-gray-100 transition-colors duration-200"
              >
                {columns.map((column, index) => (
                  <td key={index} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {/* Regular column accessor */}
                    {column.accessor && column.accessor !== 'time' ? (
                      item[column.accessor]
                    ) : column.Cell ? (
                      column.Cell({ row: item })
                    ) : null}

                    {/* Special case for time formatting */}
                    {column.accessor === 'time' && (
                      <span>{new Date(item.time).toLocaleString()}</span>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
