import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Table from './Table'; // Assume you have a Table component
import Navbar from './Navbar';

const CoinTransactionsPage = () => {
  const { machineId } = useParams();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(0); // Total number of records
  const [page, setPage] = useState(1);  // Current page
  const [limit, setLimit] = useState(10); // Number of rows per page, initially set to 10
  const [startDate, setStartDate] = useState(''); // Start date for filter
  const [endDate, setEndDate] = useState(''); // End date for filter

  // Function to format date as YYYY-MM-DD
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Memoize fetchTransactions to prevent recreation on every render
  const fetchTransactions = useCallback(async (page = 1, limit = 10, startDate = '', endDate = '') => {
    setLoading(true);
    setError(null);

    const API_URL = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(
        `${API_URL}/machines/${machineId}/coin-transactions?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const { data, total } = await response.json(); // Expecting paginated response
      setTransactions(data); // Update only the data for the current page
      setTotal(total);       // Total number of records from the response
      setPage(page);         // Track the current page
    } catch (error) {
      console.error('Error fetching coin-transactions:', error);
      setError('Failed to fetch coin-transactions');
    } finally {
      setLoading(false);
    }
  }, [machineId]); // Dependencies: machineId ensures fetchTransactions changes only when machineId changes

  // useEffect to load data on initial load (no date reset)
  useEffect(() => {
    // If startDate and endDate are provided (by the user), just fetch transactions for the selected dates
    if (startDate && endDate) {
      fetchTransactions(page, limit, startDate, endDate);
    } else {
      // If no date is selected, set the default to today and fetch transactions for today
      const today = getTodayDate();
      setStartDate(today);
      setEndDate(today);
      // fetchTransactions(page, limit, today, today); // Initial fetch for today

      // Set up interval to refresh data every 60 seconds for today
      const intervalId = setInterval(() => fetchTransactions(page, limit, today, today), 60000);

      // Cleanup interval on component unmount or when dates change
      return () => clearInterval(intervalId);
    }
  }, [fetchTransactions, page, limit, startDate, endDate]); // Depend on dates, page, and limit

  // Define columns based on your requirements
  const columns = [
    { Header: 'SN', accessor: 'id' },
    { Header: 'Price', accessor: 'price' },
    { Header: 'Volume', accessor: 'volume' },
    { Header: 'Water Type', accessor: 'waterType' },
    { Header: 'Date', accessor: 'time' },
  ];

  // Handle change in limit value
  const handleLimitChange = (event) => {
    setLimit(Number(event.target.value)); // Update the limit based on user input
    setPage(1); // Reset to the first page when the limit changes
  };

  // Handle date change
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  return (
    <div>
      <Navbar />

      <div className="container mx-auto p-4">
        <h1 className="text-xl font-semibold mb-4">Coin-Based Transactions</h1>

        {loading}
        {error && <p className="text-red-500">{error}</p>}

        {/* Date Filter Section */}
        <div className="mb-4 flex items-center">
          <label htmlFor="startDate" className="mr-2">Start Date:</label>
          <input
            type="date"
            id="startDate"
            value={startDate}
            onChange={handleStartDateChange}
            className="border p-2 rounded"
          />
          <label htmlFor="endDate" className="ml-4 mr-2">End Date:</label>
          <input
            type="date"
            id="endDate"
            value={endDate}
            onChange={handleEndDateChange}
            className="border p-2 rounded"
          />
          <button
            onClick={() => fetchTransactions(1, limit, startDate, endDate)} // Refetch data when applying filter
            className="ml-4 bg-blue-500 text-white px-3 py-1 rounded"
          >
            Filter
          </button>
        </div>

        {/* User selects rows per page */}
        <div className="mb-4">
          <label htmlFor="limit" className="mr-2">Rows per page:</label>
          <select
            id="limit"
            value={limit}
            onChange={handleLimitChange}
            className="border p-2 rounded"
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={500}>500</option>
            <option value={1000}>1000</option>
          </select>
        </div>

        <Table columns={columns} data={transactions} />

        {/* Pagination Controls */}
        <div className="flex justify-between mt-4">
          <button
            onClick={() => fetchTransactions(page - 1, limit, startDate, endDate)}
            disabled={page <= 1}
            className="bg-blue-500 text-white px-3 py-1 rounded disabled:opacity-50"
          >
            Previous
          </button>
          <span>
            Page {page} of {Math.ceil(total / limit)}
          </span>
          <button
            onClick={() => fetchTransactions(page + 1, limit, startDate, endDate)}
            disabled={page >= Math.ceil(total / limit)}
            className="bg-blue-500 text-white px-3 py-1 rounded disabled:opacity-50"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default CoinTransactionsPage;
